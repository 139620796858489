<template>
  <section :class="slideClasses" v-bind="slideData">
    <div :class="outerClasses">
      <AbsoluteBlock
        v-for="(block, index) in absoluteBlocks"
        :key="index"
        :block="block"
      />
      <div class="flex w-full h-full items-center text-left">
        <div :class="innerClasses">
          <RelativeBlock
            v-for="(block, index) in relativeBlocks"
            :key="index"
            :block="block"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';

import {
  DIRECTION_MAPPINGS,
  ALIGN_MAPPINGS,
  JUSTIFY_MAPPINGS,
  PADDING_MAPPINGS
} from '../helpers/layout_mappings';

import AbsoluteBlock from './Blocks/AbsoluteBlock.vue'
import RelativeBlock from './Blocks/RelativeBlock.vue'

export default {
  name: 'SlideComponent',
  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const slideClasses = computed(() => [
      'h-full',
      'w-full',
      'relative',
      'actual-slide',
      { 'outline': props.slide.bordered },
      { 'outline-gray-200': props.slide.bordered }
    ]);

    const slideData = computed(() => ({
      'data-background-color': props.slide.layout.background_color
    }));

    const outerClasses = computed(() => [
      'h-full',
      'w-full',
      'relative',
      { 'overflow-hidden': props.slide.allow_overflow }
    ]);

    const innerClasses = computed(() => [
      'h-full',
      'w-full',
      'flex',
      'overflow-y-auto',
      DIRECTION_MAPPINGS[props.slide.layout.direction] || '',
      ALIGN_MAPPINGS[props.slide.layout.align] || '',
      JUSTIFY_MAPPINGS[props.slide.layout.justify] || '',
      PADDING_MAPPINGS.top[props.slide.layout.padding_top] || '',
      PADDING_MAPPINGS.right[props.slide.layout.padding_right] || '',
      PADDING_MAPPINGS.bottom[props.slide.layout.padding_bottom] || '',
      PADDING_MAPPINGS.left[props.slide.layout.padding_left] || ''
    ]);

    const absoluteBlocks = computed(() => props.slide.blocks.filter(block => block.absolute));
    const relativeBlocks = computed(() => props.slide.blocks.filter(block => !block.absolute));

    return {
      slideClasses,
      slideData,
      outerClasses,
      innerClasses,
      absoluteBlocks,
      relativeBlocks
    };
  },
  components: {
    AbsoluteBlock,
    RelativeBlock
  }
};
</script>
