<template>
  <div>
    <h1>Hello from Vue.js</h1>
    <p>{{ name }}</p>
  </div>
</template>

<script>
import { toRefs } from 'vue';

export default {
  name: "Hello",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { name } = toRefs(props);
    return {
      name
    };
  }
};
</script>
