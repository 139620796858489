import { Controller } from "@hotwired/stimulus"
import tippy, { roundArrow, hideAll } from 'tippy.js'

export default class extends Controller {
  static targets = ['origin', 'template']

  connect() {
    const origin = this.hasOriginTarget ? this.originTarget : this.element

    const {
      tippyContent,
      tippyPlacement,
      tippyArrow,
      tippyTrigger,
      tippyWidth
    } = origin.dataset

    if (tippyContent || this.hasTemplateTarget) {
      this.instance = tippy(origin, {
        content: tippyContent || this.templateTarget.innerHTML,
        allowHTML: true,
        placement: tippyPlacement || 'bottom-start',
        arrow: tippyArrow ? roundArrow : false,
        theme: 'light',
        maxWidth: parseInt(tippyWidth) || undefined,
        trigger: tippyTrigger || undefined,
        interactive: true,
        appendTo: document.body
      })
    }
  }

  hide() {
    try {
      this.instance.hide()
    } catch (error) {
      hideAll()
    }
  }
}