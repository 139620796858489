<template>
  <div
    class="sl-block absolute"
    :style="block.outer_style"
  >
    <div class="sl-block-content h-full w-full" data-inline-svg="false">
      <div :style="block.inner_style" class="h-full w-full">
        <!-- Image w/attachment -->
        <div v-if="isImageWithAttachment" class="relative h-full w-full rounded-lg overflow-hidden">
          <img :src="block.image_url" class="w-full h-full object-cover" />
        </div>

        <!-- Drawing or Shape -->
        <div style="display: contents" v-else-if="isDrawingOrShape" v-html="block.html"></div>

        <!-- Image w/o attachment -->
        <div v-else-if="isImageWithoutAttachment" class="flex h-full w-full justify-center items-center p-6">
          <img :src="block.placeholder_image_url" class="w-full h-full object-cover rounded-lg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useBlockHelpers } from '../../composables/useBlockHelpers';

export default {
  name: 'AbsoluteBlock',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { isImageWithAttachment, isImageWithoutAttachment, isDrawingOrShape } = useBlockHelpers(props);

    return {
      isImageWithAttachment,
      isImageWithoutAttachment,
      isDrawingOrShape
    };
  }
};
</script>
