import { inject, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useBlockUpdate } from './useBlockUpdate'

export function useBlockEditor(props) {
  const { updateBlock } = useBlockUpdate()
  const currentEditor = inject('currentEditor')
  const slides = inject('parsedSlides')
  const blockRef = ref(null)

  const onUpdatedBlock = (html, field = null) => {
    const slideId = slides.value.find(slide => slide.blocks.some(b => b.id === props.block.id))?.id
    updateBlock(props.block.update_path, html, slideId, field)
  }

  onClickOutside(blockRef, (event) => {
    if (currentEditor.value === props.block.editor || !props.block.editor) {
      const toolbar = document.querySelector('.tiptap-toolbar')
      if (toolbar && !toolbar.contains(event.target)) {
        currentEditor.value = null
      }
    }
  })

  const setCurrentEditor = () => {
    if (props.block.editor) {
      currentEditor.value = props.block.editor
    }
  }

  const updateEditor = (editor) => {
    props.block.editor = editor
  }

  return {
    onUpdatedBlock,
    setCurrentEditor,
    updateEditor,
    blockRef
  }
}
