import { inject } from 'vue'

export function useSaveStatus() {
  const status = inject('saveStatus')

  const setStatus = (message) => {
    status.value = message
  }

  const setStatusToLastSaved = () => {
    status.value = `Last saved at ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
  }

  return {
    status,
    setStatus,
    setStatusToLastSaved,
  }
}
