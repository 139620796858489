import { Controller } from '@hotwired/stimulus'
import * as actions from "@hotwired/turbo"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["stream"]
  static values = {
    lessonId: Number,
    classId: Number,
    signedStreamName: String,
    channel: String,
    currentActiveTime: {
      type: Number,
      default: 0
    },
    deviceFree: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.subscription = consumer.subscriptions.create(this.channel)
    this.start()
    this.setupVisibilityEvents()
  }
  
  start() {
    this.focused = true
    this.lastActivity = Date.now()
    this.activeTime = 0
    this.visibilityState = document.visibilityState
  
    this.interval = setInterval(() => {
      if (this.visible) {
        const now = Date.now()
        const delta = now - this.lastActivity
  
        this.activeTime += delta
        this.lastActivity = now
  
        // Check if a full minute of active time has passed
        if (this.activeTime >= 60000) {
          this.subscription.send({
            type: "active_time",
            device_free: this.deviceFreeValue
          })
  
          // Reset active time
          this.activeTime = 0
        }
      } else {
        this.lastActivity = Date.now()
      }
  
      this.visibilityState = document.visibilityState
    }, 1000) // Check every second
  }
  
  setupVisibilityEvents() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible" && this.visibilityState === "hidden") {
        this.lastActivity = Date.now()
      }
    })

    window.addEventListener("blur", () => {
      this.focused = false
    })

    window.addEventListener("focus", () => {
      this.focused = true
      this.lastActivity = Date.now()
    })
  }

  disconnect() {
    clearInterval(this.interval)
  }

  get channel() {
    const channel = this.channelValue
    const signed_stream_name = this.signedStreamNameValue

    return { channel, signed_stream_name }
  }

  get visible() {
    return document.visibilityState === "visible" && this.focused
  }
}