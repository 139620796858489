<template>
  <div
    :class="outerClasses"
    :style="outerStyles"
    @click="setCurrentEditor"
  >
    <div class="relative h-full w-full" data-inline-svg="false" data-editable="true">
      <!-- Text -->
      <div v-if="block.block_type == 'text'" data-type="text" class="trix-content" ref="blockRef">
        <ContentEditor :content="block.html_content" :onUpdate="onUpdatedBlock" @editor-created="updateEditor" />
      </div>

      <!-- Spark -->
      <SparkPlaceholder v-else-if="block.has_spark" :spark_image_url="block.spark_image_url" />

      <!-- Section -->
      <Section v-else-if="block.block_type == 'section'" :section="block.section" :block="block"/>

      <!-- Embed -->
      <Embed v-else-if="block.block_type == 'embed'" :embed="block.embed" />

      <!-- Image w/attachment -->
      <div v-else-if="isImageWithAttachment" class="w-full h-full object-cover rounded-lg overflow-hidden p-6" :style="block.inner_style">
        <img :src="block.image_url" class="w-full h-full object-cover rounded-lg" />
      </div>

      <!-- Image w/o attachment -->
      <div v-else-if="isImageWithoutAttachment" class="flex h-full w-full justify-center items-center p-6">
        <img :src="block.image_url" class="w-full h-full object-cover rounded-lg" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useBlockHelpers } from '../../composables/useBlockHelpers'
import { useBlockEditor } from '../../composables/useBlockEditor'
import SparkPlaceholder from '../SparkPlaceholder.vue'
import Embed from '../Embed.vue'
import Section from '../Section.vue'
import ContentEditor from '../ContentEditor.vue'

export default {
  name: 'RelativeBlock',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  components: {
    SparkPlaceholder,
    Embed,
    Section,
    ContentEditor
  },
  setup(props) {
    const { parseStyleString, isImageWithAttachment, isImageWithoutAttachment } = useBlockHelpers(props)
    const { onUpdatedBlock, setCurrentEditor, updateEditor, blockRef } = useBlockEditor(props)

    const outerStyles = computed(() => ({
      ...parseStyleString(props.block.outer_style),
      position: 'unset'
    }))

    const outerClasses = computed(() => [
      'wellio-block',
      'block',
      'text-inherit',
      'h-full',
      'w-full',
      `self-${props.block.align}`,
      `justify-self-${props.block.justify}`
    ])

    return {
      outerStyles,
      outerClasses,
      isImageWithAttachment,
      isImageWithoutAttachment,
      onUpdatedBlock,
      setCurrentEditor,
      updateEditor,
      blockRef
    }
  }
}
</script>
