import { computed } from 'vue';

export function useBlockHelpers(props) {
  const parseStyleString = (styleString) => {
    if (!styleString) return {}
    return styleString.split(';').reduce((acc, style) => {
      const [key, value] = style.split(':').map(s => s.trim())
      if (key) {
        acc[key] = value
      }
      return acc;
    }, {})
  }

  const isImageWithAttachment = computed(() => {
    return props.block.block_type === 'image' && props.block.image_url;
  });

  const isImageWithoutAttachment = computed(() => {
    return props.block.block_type === 'image' && !props.block.image_url;
  });

  const isDrawingOrShape = computed(() => {
    return props.block.block_type === 'drawing' || props.block.block_type === 'shape';
  });

  const deepMergeBlocks = (currentBlocks, updatedBlocks) => {
    return currentBlocks.map(currentBlock => {
      const updatedBlock = updatedBlocks.find(block => block.id === currentBlock.id)
      return updatedBlock ? { ...currentBlock, ...updatedBlock } : currentBlock
    })
  }

  return {
    parseStyleString,
    isImageWithAttachment,
    isImageWithoutAttachment,
    isDrawingOrShape,
    deepMergeBlocks
  };
}
