import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static values = {
    text: String,
    tooltip: {
      type: String,
      default: 'Copy shareable link'
    },
    copiedTooltip: {
      type: String,
      default: 'Link copied!'
    }
  }

  connect() {
    this.initTooltip()
  }

  copy() {
    navigator.clipboard.writeText(this.textValue)
      .then(() => {
        this.tippy.setContent(this.copiedTooltipValue)
      })
  }

  initTooltip() {
    this.tippy = tippy(this.element, {
      content: this.tooltipValue,
      trigger: 'mouseenter',
      placement: 'bottom',
      theme: 'light',
      delay: [300, 0],
      hideOnClick: false,
      onHidden: () => {
        this.tippy.setContent(this.tooltipValue)
      }
    })
  }
}