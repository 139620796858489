<template>
  <div>
    <!-- YouTube Videos -->
    <div v-if="isYouTube">
      <div
        :class="youtubeWrapperClass"
        data-provider="youtube"
        data-controller="youtube"
        :data-youtube-start-value="embed.start"
        :data-youtube-end-value="embed.end"
        :data-youtube-id-value="embed.external_embed_id"
        :style="youtubeStyle">
        <div :class="youtubeVideoClass" data-youtube-target="video"></div>
      </div>
    </div>

    <!-- YouTube Shorts -->
    <div v-if="isYouTubeShorts">
      <div
        class="flex items-center max-w-[320px] h-[690px] overflow-hidden rounded-lg aspect-[9/16] w-fit mx-auto"
        data-provider="youtube_shorts"
        v-html="embed.html"
      />
    </div>

    <!-- TikTok Videos -->
    <div v-if="isTikTok">
      <div
        class="pointer-events-auto max-w-[320px] h-[550px] overflow-hidden rounded-lg w-fit m-auto"
        :class="{ 'mt-10': embed.embeddable == 'Slide' }"
        data-provider="tiktok"
        v-html="embed.html"
      />
    </div>

    <!-- Other Embeds -->
    <div v-if="isOtherEmbed">
      <div class="flex flex-col items-center justify-center" data-video>
        <iframe
          class="w-10/12 rounded-lg"
          id="ytplayer"
          type="text/html"
          width="640"
          height="360"
          :src="embed.original_url"
          allow="fullscreen;"
          frameborder="0">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Embed',
  props: {
    embed: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const isYouTube = computed(() => props.embed.provider === 'youtube');
    const isYouTubeShorts = computed(() => props.embed.provider === 'youtube_shorts');
    const isTikTok = computed(() => props.embed.provider === 'tiktok');
    const isOtherEmbed = computed(() => props.embed.provider === 'other');

    const youtubeWrapperClass = computed(() => {
      return props.embed.embeddable === 'Block'
        ? 'relative w-full h-full flex justify-center overflow-hidden px-10'
        : 'relative w-full rounded-lg';
    });

    const youtubeStyle = computed(() => {
      return props.embed.embeddable === 'Block'
        ? { aspectRatio: props.embed.aspect_ratio }
        : { paddingBottom: props.embed.aspect_ratio_percent + '%' };
    });

    const youtubeVideoClass = computed(() => {
      return props.embed.embeddable === 'Block'
        ? 'w-full h-full rounded-lg'
        : 'absolute top-0 left-0 w-full h-full border-0 rounded-lg';
    });

    return {
      isYouTube,
      isYouTubeShorts,
      isTikTok,
      isOtherEmbed,
      youtubeWrapperClass,
      youtubeStyle,
      youtubeVideoClass
    };
  }
};
</script>
