<template>
  <div class="h-[calc(100vh-178px)]">
    <Toolbar class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3 flex justify-between h-12 z-10" />
    <div class="reveal">
      <div class="slides">
        <section v-if="parsedSlides.length === 0">Loading...</section>
        <template v-else>
          <Slide
            v-for="(slide, index) in parsedSlides"
            :key="index"
            :slide="slide"
            :index="index"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, provide } from 'vue'
import Reveal from 'reveal.js'
import FsFx from 'reveal.js-fsfx'
import Slide from './Slide.vue'
import Toolbar from './Toolbar.vue'
import { useBlockHelpers } from '../composables/useBlockHelpers';

export default {
  name: 'SlidesEditor',
  props: {
    slides: {
      type: String,
      required: true,
      validator(value) {
        try {
          JSON.parse(value)
          return true
        } catch (e) {
          console.error('Invalid JSON:', e)
          return false
        }
      }
    }
  },
  components: {
    Slide,
    Toolbar
  },
  setup(props) {
    const parsedSlides = ref([])
    const currentEditor = ref(null)
    const saveStatus = ref('No changes')
    const { deepMergeBlocks } = useBlockHelpers()

    const parseSlides = () => {
      try {
        parsedSlides.value = JSON.parse(props.slides)
      } catch (e) {
        console.error('Error parsing slides:', e)
      }
    }

    const updateSlides = (updatedSlideData) => {
      const index = parsedSlides.value.findIndex(slide => slide.id === updatedSlideData.id)
      if (index !== -1) {
        const currentSlide = parsedSlides.value[index]
        const mergedBlocks = deepMergeBlocks(currentSlide.blocks, updatedSlideData.blocks)
        parsedSlides.value[index] = {
          ...currentSlide,
          ...updatedSlideData,
          blocks: mergedBlocks
        }
      }
    }

    provide('updateSlides', updateSlides)
    provide('parsedSlides', parsedSlides)
    provide('currentEditor', currentEditor)
    provide('saveStatus', saveStatus)

    onMounted(() => {
      parseSlides()

      new Reveal({
        plugins: [FsFx],
        slideNumber: 'c/t',
        hash: true,
        fsfx: {
          auto: {
            position: {
              left: '20px',
              top: '20px',
              right: false
            }
          }
        },
        width: 1280,
        height: 720
      }).initialize()
    })

    return {
      parsedSlides,
      currentEditor
    }
  }
}
</script>
