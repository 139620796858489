import { inject } from 'vue'
import { patch } from '@rails/request.js'
import { useSaveStatus } from './useSaveStatus'

export function useBlockUpdate() {
  const updateSlides = inject('updateSlides')
  const { setStatus, setStatusToLastSaved } = useSaveStatus()

  const createBlockPayload = (html, field) => {
    return { html_content: html, field: field }
  }

  const updateBlock = async (updatePath, html, slideId, field = null) => {
    const startTime = Date.now()
    setStatus('Saving...')
    try {
      const response = await patch(updatePath, {
        body: JSON.stringify({
          block: createBlockPayload(html, field),
          slide_id: slideId
        }),
        responseKind: 'json'
      })

      if (response.ok) {
        const updatedSlide = await response.json
        updateSlides(updatedSlide)
        const elapsedTime = Date.now() - startTime
        const remainingTime = Math.max(0, 1000 - elapsedTime)

        setTimeout(setStatusToLastSaved, remainingTime)
      } else {
        console.error('Error updating block:', await response.json)
        setStatus('⚠️ Error saving')
      }
    } catch (error) {
      console.error('Error updating block:', error)
      setStatus('⚠️ Error saving')
    }
  }

  return { updateBlock }
}
